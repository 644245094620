@import '../../styles/vars.scss';

.bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;

  &--mobile {
    display: none;
  }
}

.content {
  position: relative;
  height: 37.5rem;
  box-sizing: border-box;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.info {
  padding-bottom: 5.625rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: auto;
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.text {
  font-weight: 500;
  color: var(--grey-f3);
  max-width: 15.8125rem;
  margin: 0;
  line-height: 1.33;
  letter-spacing: -0.02em;
}

.title {
  font-weight: 500;
  color: var(--grey-f3);
  max-width: 30.0625rem;
  margin: 0;
  line-height: 1.11;
}

.mainLogo {
  position: absolute;
  right: 5rem;
  top: 0;
  bottom: 0;
  margin: auto 0;

  path {
    fill: var(--white);
  }
}

.tags {
  color: var(--white);

  a {
    position: relative;
    z-index: 1;
  }

  * {
    color: var(--white);
  }
}

.author {
  z-index: 2;
  color: var(--white);
}

@media (max-width: 62rem) {
  .bg {
    display: none;

    &--mobile {
      display: block;
      height: 100%;
    }
  }

  .content {
    padding: 1.5rem 0.5rem 1.5rem 0;
  }

  .info {
    justify-content: normal;
    padding-bottom: 3.6875rem;
    gap: 1.25rem;
  }

  .text {
    font-size: 0.875rem;
    margin-top: 0;
    line-height: 1.28;
    letter-spacing: -0.01em;
    max-width: 12.5rem;
  }

  .title {
    font-size: 2.25rem;
    max-width: 80%;
  }

  .mainLogo {
    right: 0;
    height: 216px;
    width: auto;
    margin: auto 0 0;
    bottom: 1.5rem;
  }
}
