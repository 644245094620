.link {
  line-height: normal;
  display: flex;
  align-items: center;

  span {
    display: block;
    color: var(--text);
    font-size: 0.8125rem;
    font-weight: 600;
    letter-spacing: 0.01em;
  }
}

.title {
  padding: 0 0.25rem;
  margin-bottom: -0.125rem;
  text-transform: uppercase;
}
