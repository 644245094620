.block {
  margin: 6.25rem 0;
}

.topWrapper {
  margin-top: 2.5rem;

  &.isWorkPage {
    margin-top: 3rem;
  }
}

.reviewsBlock {
  margin-bottom: 2rem;
  overflow: hidden;
}

.title {
  position: relative;
  width: fit-content;
  margin-top: 3.125rem;

  .badge {
    position: absolute;
    top: 0.56rem;
    right: 0;
    transform: translateX(calc(100% + 0.62rem));
  }

  h1,
  h2 {
    margin: 0;
  }
}

.sup {
  letter-spacing: -0.01em;
  font-weight: 700;
  font-size: 0.8125rem;
  top: -1em;
  right: 0.2em;
}

.titleLink {
  margin: 0;
  display: block;
}

.count {
  color: var(--counter-color);
}

.buttonLink {
  display: block;
  padding: 0.75rem 0;
  background-color: var(--main-btn-back);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.00714em;

  &.mainTopLink {
    margin-bottom: 1rem;
  }

  &.authorsLink {
    margin-top: 1.5rem;
  }
}

.grid {
  margin: 2.5rem 0 0;
  padding: 0;
  display: grid;
  grid-gap: 0 min(3.4722vw, 50px);
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: 'slider slider slider slider vacancies vacancies';
}

.slider {
  grid-area: slider;
  overflow: hidden;
}

.postWork {
  gap: 0.3rem;
  display: flex;
  flex-direction: column;
}

.sliderInner {
  margin-top: 1.25rem;
}

.vacancies {
  grid-area: vacancies;
  padding-top: 0.5rem;
}

.articleItem {
  display: flex;
}

.showMore {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0.75rem 0;
  font-size: 0.875rem;
  text-align: center;
  border: none;
  background-color: var(--gray_t3);
  cursor: pointer;
  color: var(--text);
}

.sliderMobile {
  display: none;
}

@media (max-width: 60rem) {
  .slider {
    display: none;
  }

  .sliderMobile {
    display: block;
  }

  .block {
    margin: 3.75rem 0;
  }

  .grid {
    grid-template-columns: 100%;
    grid-template-areas:
      'slider'
      'vacancies';
    margin: 0;
    gap: 2rem;
  }

  .reviewsSlider {
    margin-top: 3.125rem;
  }
}

@media (max-width: 49rem) {
  .topWrapper {
    margin-top: 1.5rem;
  }
}
