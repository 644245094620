@import '../../../styles/vars.scss';

.aspectRatioBox {
  position: relative;
  width: 100%;
  padding-top: 145%;
  overflow: hidden;
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--grey-bf);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black_t3);
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
  }

  &--dark::after {
    opacity: 1;
  }

  &--promo::after {
    opacity: 0;
  }
}

.imageBox {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 50 / 89;
  opacity: 0;
  transition: opacity 0.2s ease;
  position: absolute;
  top: 0;
  left: 0;

  &--active {
    opacity: 1;
  }

  &--promo {
    opacity: 1;
    transform: scale(1.2);
    transition: transform 2s ease-in-out;
  }

  &--hidden {
    opacity: 0;
  }
}

.blur {
  backdrop-filter: blur(1.485rem);
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  bottom: 0;

  &--bottom {
    z-index: 0;
  }

  &--bottomGeneratedStory {
    z-index: 3;
  }
}

.titleBox {
  padding: 1.25rem 1.875rem 0;
  color: var(--white);
  background-color: var(--black_t02);
  pointer-events: none;

  &--hidden {
    opacity: 0;
    height: 0;
    padding: 0;
  }

  &--link {
    display: inline-flex;
    align-items: center;
    padding: 0.75rem 1.875rem;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.7;
    pointer-events: all;
    z-index: 2;

    svg {
      margin-left: 0.125rem;
      width: 0.25rem;
      margin-top: 1px;
    }
  }

  &--span {
    opacity: 0.5;
    padding: 0 1.875rem 1rem;
    outline: none;
    border: none;
  }

  &--linkPromo {
    padding-bottom: 0;
  }

  &--linkPromoBottom {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 1rem;

    a {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      color: var(--white);
    }

    button {
      opacity: 0.5;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      color: var(--white);
    }
  }
}

.subtitlePreview {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.7;
  color: var(--white);
  margin: 0;
}

.title {
  margin: 0;
  font-weight: 500;
  line-height: 1.3;
  color: var(--white);
  letter-spacing: -0.03em;
}

.player {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 1;

  &--active {
    background: linear-gradient(
      0deg,
      var(--black-1e-t8) 0%,
      var(--black-1e-t8) 100%
    );
    filter: brightness(50%);
  }
}

.playButton {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translateX(-50%);
  width: 17.5%;
  aspect-ratio: 1/1;
  padding: 0;
  background-color: transparent;
  border: none;
  overflow: hidden;
  cursor: pointer;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

.pauseButton {
  position: absolute;
  top: 1.875rem;
  left: 1.875rem;
  width: auto;
  max-height: 1.625rem;
  height: 100%;
  padding: 0;
  z-index: 2;
  background-color: transparent;
  border: none;
  overflow: hidden;
  cursor: pointer;

  svg {
    display: block;
    width: auto;
    height: 100%;
  }
}

.soundButton {
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  width: auto;
  aspect-ratio: 1/1;
  max-height: 1.625rem;
  height: 100%;
  z-index: 2;
  padding: 0;
  background-color: transparent;
  border: none;
  transform: translate3d(0, 0, 0);
  cursor: pointer;

  svg {
    display: block;
    width: auto;
    height: 100%;
  }
}

.progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  z-index: 3;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 0.1875rem;
  border: none;
  border-radius: 0;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  z-index: 3;

  &--show {
    opacity: 1;
    pointer-events: all;
  }
  z-index: 3;
}

.progress::-webkit-slider-thumb,
.progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--black);
  border: none;
  border-radius: 0;
  height: 0.1875rem;
  width: 0.1875rem;
  margin-top: 0;
  box-shadow: calc(-100vmax - 2px) 0 0 100vmax var(--black);
  clip-path: polygon(
    100% 0,
    0 0,
    0 0px,
    -100vmax 0px,
    -100vmax 1px,
    0 1.25rem,
    0 100%,
    100% 100%
  );
}

.progress::-moz-range-thumb,
.progress::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: var(--black);
  border: 0px solid var(--black);
  border-radius: 0;
  height: 0.1875rem;
  width: 0.1875rem;
  margin-top: 0;
  box-shadow: calc(-100vmax - 0.1875rem) 0 0 100vmax var(--black);
  clip-path: polygon(
    100% 0,
    0 0,
    0 0.1875rem,
    -100vmax 0.1875rem,
    -100vmax 0.1875rem,
    0 0.1875rem,
    0 100%,
    100% 100%
  );
}

.progress::-webkit-slider-runnable-track,
.progress::-webkit-slider-runnable-track {
  height: 0.1875rem;
  border-radius: 0;
  border: 0px solid var(--white);
  box-shadow: 0px 0px 0px var(--black);
  background: var(--white);
}

.subtitle {
  box-sizing: border-box;
  position: absolute;
  left: 1.875rem;
  top: 50%;
  width: calc(100% - 3.75rem);
  margin: 0;
  font-weight: 500;
  text-align: left;
  transform: translate3d(0, -50%, 0);
  z-index: 3;

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }

  &--promo {
    top: 6.875rem;
    transform: translate3d(0, 0, 0);
  }

  span {
    line-height: 1.4;
    color: var(--white);
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0.125rem 0;
  }
}

.read {
  box-sizing: border-box;
  padding: 0;
  padding-left: 1.875rem;
  font-size: 1rem;
  color: var(--white);
  line-height: 1.9;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  background-color: var(--blue-00b);
  margin-top: 1rem;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  width: 100%;
  height: 3.125rem;
  column-gap: 1rem;
  cursor: pointer;
}

.arrow {
  width: 0.375rem;
  height: 0.625rem;
  margin: 0 1.875rem 0 0.3125rem;
}

.contentPromoImage {
  img {
    top: 1rem;
  }
}

.close svg path {
  stroke: var(--black);
  z-index: 5;
}

div.banner {
  background: var(--white);
  gap: 0.5rem;
  z-index: 3;

  p {
    display: flex;
    max-width: none;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 0.5rem;
  }
}

@media (max-width: 100rem) {
  .subtitle--promo {
    font-size: 1.0625rem;
  }
}

@media (max-width: 93.75rem) {
  .read {
    font-size: 0.75rem;
    column-gap: 0.75rem;
    padding-left: 0.75rem;
  }

  .arrow {
    margin: 0 0.75rem 0 0.3125rem;
  }

  .subtitle--promo {
    top: 5rem;
    font-size: 1rem;
  }
}

@media (max-width: 75rem) {
  .subtitle--promo {
    top: 4rem;
    font-size: 0.9375rem;
  }
}

@media (max-width: 80rem) {
  .aspectRatioBox {
    padding-top: 170%;
  }
}

@media (max-width: $tablet) {
  .titleBox:not(
      .titleBox--hidden,
      .titleBox--span,
      .titleBox--linkPromo,
      .titleBox--linkPromoBottom
    ) {
    padding: 0.75rem 1.25rem;
  }

  .titleBox--span,
  .titleBox--linkPromo,
  .titleBox--linkPromoBottom {
    padding-left: 1.25rem;
    flex-wrap: wrap;
  }

  .titleBox--linkPromoBottom button {
    line-height: 1.7;
  }

  .titleBox--span {
    padding-bottom: 0.75rem;
  }

  .subtitleContainer {
    padding: 0.0625rem 0;
  }

  .soundButton {
    top: 1.25rem;
    right: 1.25rem;
    max-height: 1.25rem;
  }

  .subtitle {
    font-size: 1.0625rem;
    left: 1.25rem;
    width: calc(100% - 3rem);

    &--promo {
      top: 6rem;
    }
  }

  .pauseButton {
    max-height: 1.25rem;
    top: 1.25rem;
    left: 1.25rem;
  }

  .read {
    display: none;
  }

  div.banner {
    padding: 0.875rem 1rem;
  }
}

@media (max-width: $tabletBig) {
  .subtitle {
    font-size: 1.0625rem;

    &--promo {
      top: 4rem;
    }
  }

  .contentPromoImage img {
    top: 2rem;
  }
}

@media (max-width: 54.625rem) {
  .titleBox--linkPromo,
  .titleBox--linkPromoBottom {
    padding-left: 1rem;
  }
}

@media (max-width: $mobile) {
  .subtitle--promo {
    font-size: 1rem;
  }

  .subtitle--small-mobile {
    font-size: 0.875rem;
  }

  .titleBox {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .titleBox:not(
      .titleBox--hidden,
      .titleBox--span,
      .titleBox--linkPromo,
      .titleBox--link
    ) {
    padding-bottom: 0;
  }

  .titleBox--linkPromo {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-wrap: wrap;
  }

  .blur span {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 41rem) {
  .subtitle--promo {
    font-size: 0.75rem;
  }
}
