@import '../../../styles/vars.scss';

.grade {
  width: 3.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 1rem;
  line-height: 1.31;
  font-weight: 500;
}

.container {
  display: flex;
  align-items: center;
  column-gap: 1rem;

  p {
    margin: 0;
    line-height: 1.3;
    font-size: 0.8125rem;
    color: var(--contrast);
  }
}

.awful {
  background-color: var(--red-da);
}

.bad {
  background-color: var(--red-dc);
}

.normal {
  background-color: var(--yellow-f4);
}

.good {
  background-color: var(--green-52);
}

.great {
  background-color: var(--green-3a);
}

.zero {
  background-color: var(--black);
}

@media (max-width: $mobile) {
  .grade {
    font-size: 0.875rem;
  }

  .container {
    column-gap: 0.9375rem;

    p {
      font-size: 0.75rem;
    }
  }
}
