.stories {
  padding: 0.5rem 0 3.125rem;
}

.popular > section {
  padding-top: 0 !important;
}

.banner {
  cursor: pointer;
  margin-top: 2.5rem;
}

@media (max-width: 62rem) {
  .stories {
    padding-bottom: 2.375rem;
  }

  .banner {
    padding-top: 0.5rem;
  }
}
