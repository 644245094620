@import '../../../styles/vars.scss';

.container {
  width: fit-content;
  height: 2rem;
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  background-color: var(--aside-bg);
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: var(--contrast);

  &:hover {
    background-color: var(--reaction);
  }

  &.loading {
    position: relative;
    overflow: hidden;
    color: var(--contrast-t3);

    &::before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0%;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
          90deg,
          rgba(102, 113, 128, 0) 0%,
          rgba(102, 113, 128, 0.2) 54.09%,
          rgba(0, 0, 0, 0) 100%,
          rgba(0, 0, 0, 0) 100%
        ),
        rgba(218, 223, 229, 0.3);
      animation-name: gradient-animation;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      filter: blur(5px);
    }
  }
}

.reaction {
  display: block;
  margin-right: 0.625rem;
  font-size: 1rem;
  line-height: 1.31;
}

.count {
  font-size: 1rem;
  line-height: 1.31;
  font-weight: 500;
}

@keyframes gradient-animation {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}

@media (max-width: $mobile) {
  .reaction {
    font-size: 0.875rem;
  }
}
