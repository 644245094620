@import '../../../../styles/vars.scss';

.container {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 11;
  pointer-events: none;
}

@media (max-width: $mobile) {
  .container {
    bottom: 1rem;
    right: 1rem;
  }
}
