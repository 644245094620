@import '../../styles/vars.scss';

.container {
  position: relative;
}

.corner {
  position: absolute;
  width: 0.3125rem;
  height: 0.3125rem;
  background: var(--background-sec);

  &.left-top {
    left: 0;
    top: 0;
  }

  &.left-bottom {
    left: 0;
    bottom: 0;
  }

  &.right-top {
    right: 0;
    top: 0;
  }

  &.right-bottom {
    right: 0;
    bottom: 0;
  }
}
