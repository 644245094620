@import '../../styles/vars.scss';

.container {
  transform: none;
}

.top {
  margin: 0;
  padding-bottom: 1.25rem;
  color: var(--text);
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-weight: 500;
  border-bottom: 1px solid var(--border);

  p {
    margin: 0;
  }

  svg {
    display: inline-block;
    transform: translate3d(0, 0.05em, 0);
    height: 1rem;
    margin-left: 0.2em;
    flex-shrink: 0;
  }
}

.slide {
  padding-top: 1.25rem;
  min-width: 15.9375rem;
  max-width: calc((100% - min(3.4722vw, 3.125rem) * 2) / 3);

  &:not(:last-child) {
    position: relative;
    margin-right: min(3.4722vw, 3.125rem);

    &::after {
      content: '';
      position: absolute;
      left: calc(100% + min(3.4722vw, 3.125rem) / 2);
      top: 0;
      width: 0.0625rem;
      height: 1.25rem;
      background-color: var(--border);
    }
  }
}

.advert {
  position: relative;
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: var(--text);
  }
}

button.advertButton {
  font-size: 0.625rem;
}

div.advertBanner {
  padding: 0.6875rem 1.5625rem 0.6875rem 1.875rem;
  background-color: var(--advert);

  p {
    max-width: none;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
}

@media (max-width: 70rem) {
  .slide {
    padding-top: 1.25rem;
    max-width: calc((100% - 0.9375rem * 2) / 3);

    &:not(:last-child) {
      margin-right: 0.9375rem;

      &::after {
        display: none;
      }
    }
  }
}

@media (max-width: 53.125rem) {
  .slide {
    max-width: calc((100% - 1.25rem) / 2);
  }
}

@media (max-width: $mobile) {
  div.advertBanner {
    top: -0.375rem;

    p {
      display: block;
    }
  }

  .slider {
    overflow: visible;
    max-width: none;
  }
}
