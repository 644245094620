@import '../../styles/vars.scss';

@mixin imgLogo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: block;
  width: 100%;
  padding: 1.5625rem;
  box-sizing: border-box;
  background-color: var(--vacancy);
  min-height: 310px;
}

.content {
  height: calc(100% - 1.5625rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  display: flex;
  justify-content: space-between;
}

.date {
  color: var(--text);
  opacity: 0.6;
  font-size: 0.8125rem;
}

.logoBox {
  width: 6.25rem;
  height: 6.25rem;
  margin: 3.75rem auto 2rem;
  overflow: hidden;
  background-color: var(--gray-eb);

  &--flex {
    @include flexCenter;
  }

  img {
    @include imgLogo;
  }
}

.user {
  display: flex;
  column-gap: 0.625rem;
}

.avatarBox {
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
  background-color: var(--avatar);

  &--flex {
    @include flexCenter;
  }

  img {
    @include imgLogo;
  }
}

.title {
  margin: 0;
  line-height: 1.3;
  font-size: 0.8125rem;
  white-space: pre-wrap;
  word-break: break-word;
}

.review {
  margin: 0.625rem 0 0;
  font-weight: 500;
  font-size: 1rem;
  white-space: pre-wrap;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.bottom {
  margin-top: 1.875rem;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}

@media (max-width: $mobile) {
  .container {
    padding: 1.25rem;
  }

  .date {
    font-size: 0.75rem;
  }
}
