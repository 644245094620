.note {
  margin: 0;
  color: var(--black);

  &--middle {
    font-size: 0.625rem;
    line-height: 1.7;
  }

  &--big {
    font-size: 1.125rem;
    line-height: 0.94;
  }
}

.link {
  pointer-events: all;
  font-weight: 700;
  color: inherit;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 0.125rem;
    height: 0.4375rem;
  }
}
