@import '../../styles/vars.scss';

$color-scheme-1: (
  background-container: var(--blue-2b),
  soc-link-border: var(--white),
  soc-link-fill: var(--white),
  soc-link-fill-selected: var(--blue-2b),
  soc-link-background-selected: var(--white),
  text-color: var(--grey-f3),
  button-border: var(--white),
  button-color: var(--white),
  button-svg-background: var(--white),
  button-svg-fill: var(--blue-2b),
  main-logo: var(--white),
  decor: var(--white),
  corner: var(--background-sec),
  footer-logo: var(--white),
);

$color-scheme-2: (
  background-container: var(--blue-007),
  soc-link-border: var(--white),
  soc-link-fill: var(--white),
  soc-link-fill-selected: var(--blue-007),
  soc-link-background-selected: var(--white),
  text-color: var(--grey-f3),
  button-border: var(--white),
  button-color: var(--white),
  button-svg-background: var(--white),
  button-svg-fill: var(--blue-007),
  main-logo: var(--white),
  decor: var(--white),
  corner: var(--background-sec),
  footer-logo: var(--white),
);

$color-scheme-3: (
  background-container: var(--white),
  soc-link-border: var(--black),
  soc-link-fill: var(--black),
  soc-link-fill-selected: var(--white),
  soc-link-background-selected: var(--black),
  text-color: var(--black),
  button-border: var(--black),
  button-color: var(--black),
  button-svg-background: var(--red-ff),
  button-svg-fill: var(--white),
  main-logo: var(--black),
  decor: var(--black),
  corner: var(--black),
  footer-logo: var(--black),
);

$color-scheme-4: (
  background-container: var(--black),
  soc-link-border: var(--white),
  soc-link-fill: var(--white),
  soc-link-fill-selected: var(--black),
  soc-link-background-selected: var(--white),
  text-color: var(--white),
  button-border: var(--white),
  button-color: var(--white),
  button-svg-background: var(--white),
  button-svg-fill: var(--black),
  main-logo: var(--white),
  decor: var(--grey-74),
  corner: var(--white),
  footer-logo: var(--white),
);

$color-scheme-5: (
  background-container: var(--grey-f0),
  soc-link-border: var(--black),
  soc-link-fill: var(--black),
  soc-link-fill-selected: var(--grey-f0),
  soc-link-background-selected: var(--black),
  text-color: var(--black),
  button-border: var(--black),
  button-color: var(--black),
  button-svg-background: var(--black),
  button-svg-fill: var(--white),
  main-logo: var(--black),
  decor: var(--black),
  corner: var(--black),
  footer-logo: var(--black),
);

.container {
  transition: background-color 0.5s ease;
  position: relative;

  &.scheme-1 {
    background: map-get($color-scheme-1, background-container);
  }

  &.scheme-2 {
    background: map-get($color-scheme-2, background-container);
  }

  &.scheme-3 {
    background: map-get($color-scheme-3, background-container);
  }

  &.scheme-4 {
    background: map-get($color-scheme-4, background-container);
  }

  &.scheme-5 {
    background: map-get($color-scheme-5, background-container);
  }
}

.content {
  position: relative;
  height: 37.5rem;
  box-sizing: border-box;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  max-width: 102.5rem;
  margin: 0 auto;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.soc {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 0.625rem;
}

.socItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  transition: border-color 0.5s ease, fill 0.5s ease, background-color 0.5s ease;
  cursor: pointer;

  &.selected {
    &.scheme-1 {
      background: map-get($color-scheme-1, soc-link-background-selected);

      svg path {
        fill: map-get($color-scheme-1, soc-link-fill-selected);
      }
    }

    &.scheme-2 {
      background: map-get($color-scheme-2, soc-link-background-selected);

      svg path {
        fill: map-get($color-scheme-2, soc-link-fill-selected);
      }
    }

    &.scheme-3 {
      background: map-get($color-scheme-3, soc-link-background-selected);

      svg path {
        fill: map-get($color-scheme-3, soc-link-fill-selected);
      }
    }

    &.scheme-4 {
      background: map-get($color-scheme-4, soc-link-background-selected);

      svg path {
        fill: map-get($color-scheme-4, soc-link-fill-selected);
      }
    }

    &.scheme-5 {
      background: map-get($color-scheme-5, soc-link-background-selected);

      svg path {
        fill: map-get($color-scheme-5, soc-link-fill-selected);
      }
    }
  }

  &.scheme-1 {
    border: 1px solid map-get($color-scheme-1, soc-link-border);

    svg path {
      fill: map-get($color-scheme-1, soc-link-fill);
    }
  }

  &.scheme-2 {
    border: 1px solid map-get($color-scheme-2, soc-link-border);

    svg path {
      fill: map-get($color-scheme-2, soc-link-fill);
    }
  }

  &.scheme-3 {
    border: 1px solid map-get($color-scheme-3, soc-link-border);

    svg path {
      fill: map-get($color-scheme-3, soc-link-fill);
    }
  }

  &.scheme-4 {
    border: 1px solid map-get($color-scheme-4, soc-link-border);

    svg path {
      fill: map-get($color-scheme-4, soc-link-fill);
    }
  }

  &.scheme-5 {
    border: 1px solid map-get($color-scheme-5, soc-link-border);

    svg path {
      fill: map-get($color-scheme-5, soc-link-fill);
    }
  }
}

.text {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.3;
  letter-spacing: -0.03em;
  color: var(--grey-f3);
  max-width: 15.8125rem;
  transition: color 0.5s ease;
  margin: 0;

  &.scheme-1 {
    color: map-get($color-scheme-1, text-color);
  }

  &.scheme-2 {
    color: map-get($color-scheme-2, text-color);
  }

  &.scheme-3 {
    color: map-get($color-scheme-3, text-color);
  }

  &.scheme-4 {
    color: map-get($color-scheme-4, text-color);
  }

  &.scheme-5 {
    color: map-get($color-scheme-5, text-color);
  }
}

.footerLogo {
  transition: fill 0.5s ease;

  &.scheme-1 {
    path {
      fill: map-get($color-scheme-1, footer-logo);
    }
  }

  &.scheme-2 {
    path {
      fill: map-get($color-scheme-2, footer-logo);
    }
  }

  &.scheme-3 {
    path {
      fill: map-get($color-scheme-3, footer-logo);
    }
  }

  &.scheme-4 {
    path {
      fill: map-get($color-scheme-4, footer-logo);
    }
  }

  &.scheme-5 {
    path {
      fill: map-get($color-scheme-5, footer-logo);
    }
  }
}

.centeredLogo {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);

  display: flex;
  flex-direction: column;
  gap: 10.5rem;
}

.button {
  position: absolute;
  right: 50%;
  transform: translate(50%);
  bottom: 2.5rem;

  background: none;
  border: 1px solid var(--white);
  border-radius: 3.75rem;
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;

  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.01em;
  color: var(--white);

  display: flex;
  align-items: center;
  gap: 0.625rem;
  transition: border-color 0.5s ease, color 0.5s ease,
    background-color 0.5s ease;

  svg {
    border-radius: 50%;
    transition: fill 0.5s ease;
    width: 1.875rem;
    height: 1.875rem;
  }

  &.scheme-1 {
    border: 1px solid map-get($color-scheme-1, button-border);
    color: map-get($color-scheme-1, button-color);

    svg {
      background-color: map-get($color-scheme-1, button-svg-background);

      path {
        fill: map-get($color-scheme-1, button-svg-fill);
      }
    }
  }

  &.scheme-2 {
    border: 1px solid map-get($color-scheme-2, button-border);
    color: map-get($color-scheme-2, button-color);

    svg {
      background-color: map-get($color-scheme-2, button-svg-background);

      path {
        fill: map-get($color-scheme-2, button-svg-fill);
      }
    }
  }

  &.scheme-3 {
    border: 1px solid map-get($color-scheme-3, button-border);
    color: map-get($color-scheme-3, button-color);

    svg {
      background-color: map-get($color-scheme-3, button-svg-background);

      path {
        fill: map-get($color-scheme-3, button-svg-fill);
      }
    }
  }

  &.scheme-4 {
    border: 1px solid map-get($color-scheme-4, button-border);
    color: map-get($color-scheme-4, button-color);

    svg {
      background-color: map-get($color-scheme-4, button-svg-background);

      path {
        fill: map-get($color-scheme-4, button-svg-fill);
      }
    }
  }

  &.scheme-5 {
    border: 1px solid map-get($color-scheme-5, button-border);
    color: map-get($color-scheme-5, button-color);

    svg {
      background-color: map-get($color-scheme-5, button-svg-background);

      path {
        fill: map-get($color-scheme-5, button-svg-fill);
      }
    }
  }
}

.mainLogo {
  position: absolute;
  bottom: 16.125rem;
  right: -11.75rem;
  width: 32.5rem;
  height: 4rem;
  transform: rotate(90deg);
  transition: fill 0.5s ease;

  &.scheme-1 {
    path {
      fill: map-get($color-scheme-1, main-logo);
    }
  }

  &.scheme-2 {
    path {
      fill: map-get($color-scheme-2, main-logo);
    }
  }

  &.scheme-3 {
    path {
      fill: map-get($color-scheme-3, main-logo);
    }
  }

  &.scheme-4 {
    path {
      fill: map-get($color-scheme-4, main-logo);
    }
  }

  &.scheme-5 {
    path {
      fill: map-get($color-scheme-5, main-logo);
    }
  }
}

.decor {
  position: absolute;
  transition: background-color 0.5s ease, opacity 0.5s ease;

  &.scheme-1 {
    background: map-get($color-scheme-1, decor);
    opacity: 0.2;
  }

  &.scheme-2 {
    background: map-get($color-scheme-2, decor);
    opacity: 0.2;
  }

  &.scheme-3 {
    background: map-get($color-scheme-3, decor);
    opacity: 0.05;
  }

  &.scheme-4 {
    background: map-get($color-scheme-4, decor);
    opacity: 0.2;
  }

  &.scheme-5 {
    background: map-get($color-scheme-5, decor);
    opacity: 0.05;
  }

  &.one {
    right: 12.875rem;
    top: 0;
    width: 13rem;
    height: 12.3125rem;
  }

  &.two {
    left: 0;
    bottom: 0;
    width: 13.0625rem;
    height: 12.3125rem;
  }

  &.three {
    width: 6.125rem;
    height: 5.8125rem;
    top: 0;
    right: 25.875rem;
  }

  &.four {
    width: 6.5rem;
    height: 6.125rem;
    bottom: 0;
    left: 19.5625rem;
  }

  &.five {
    width: 6.5rem;
    height: 6.1875rem;
    bottom: 6.125rem;
    left: 13.0625rem;
  }
}

.corner {
  position: absolute;
  width: 0.3125rem;
  height: 0.3125rem;
  transition: background-color 0.5s ease;

  &.left-top {
    left: 0;
    top: 0;
  }

  &.left-bottom {
    left: 0;
    bottom: 0;
  }

  &.right-top {
    right: 0;
    top: 0;
  }

  &.right-bottom {
    right: 0;
    bottom: 0;
  }

  &.scheme-1 {
    background: map-get($color-scheme-1, corner);
  }

  &.scheme-2 {
    background: map-get($color-scheme-2, corner);
  }

  &.scheme-3 {
    background: map-get($color-scheme-3, corner);
  }

  &.scheme-4 {
    background: map-get($color-scheme-4, corner);
  }

  &.scheme-5 {
    background: map-get($color-scheme-5, corner);
  }
}

@media (max-width: 62rem) {
  .content {
    padding: 1.5rem;
  }

  .soc {
    gap: 0;
    justify-content: space-between;
  }

  .info {
    justify-content: normal;
  }

  .footerLogo {
    margin-top: auto;
    width: 1.9375rem;
    height: 1rem;
  }

  .text {
    margin-top: 4.75rem;
    font-size: 0.94rem;
    line-height: 1.6;
    letter-spacing: -0.04em;
  }

  .mainLogo {
    width: 26.25rem;
    height: 3.3125rem;
    right: -10.5rem;
    bottom: 13rem;
  }

  .button {
    bottom: 5.625rem;
  }

  .decor {
    &.one {
      width: 6.0625rem;
      height: 2.875rem;
      left: 0;
      top: 18.4375rem;
    }

    &.two {
      width: 3.0625rem;
      height: 2.875rem;
      left: 0;
      bottom: 13.3125rem;
    }

    &.three {
      width: 3.0625rem;
      height: 2.875rem;
      left: 0;
      top: auto;
      bottom: 4.25rem;
    }

    &.four {
      width: 6.0625rem;
      height: 2.875rem;
      left: 11rem;
    }

    &.five {
      display: none;
    }
  }
}
