@import '../../../../styles/vars.scss';

.container {
  display: flex;
}

.button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  pointer-events: all;
  font-size: 1.125rem;
  color: var(--grey-93);
  line-height: 0.9;
  border-bottom: 1px dashed var(--grey-93);
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 0.875rem 1.5625rem 0.9375rem 1.875rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background);
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &--active {
    opacity: 1;
    pointer-events: all;
  }
}

.text {
  max-width: 15em;
  margin: 0;
  font-size: 0.625rem;
  color: var(--black);
}

.textBlock {
  display: block;
  line-height: 1.2;
}

.bold {
  font-weight: 700;
}

.advert {
  padding: 0.125rem 0;
}

.close {
  width: 0.875rem;
  padding: 0;
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media (max-width: $tablet) {
  .banner {
    padding: 0.875rem 1.5rem;
  }
}
