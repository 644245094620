.container {
  width: fit-content;
  margin-top: 1rem;
  padding: 1.625rem;
  border-radius: 2.3125rem;
  overflow: hidden;
  border: 0.125rem solid;
  background-color: var(--white);
  box-shadow: 0.1875rem 0.1875rem 0.3875rem 0 var(--black_t25);
  pointer-events: all;
  transition: transform 0.3s ease-out;

  &.slideOut {
    transform: translateX(150%);
  }

  &.slideIn {
    animation-name: slideIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }
}

.error {
  border-color: var(--red-ff);
  color: var(--red-ff);
}

.top {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;

  path {
    fill: var(--black);
  }
}

.close {
  width: 0.625rem;
  height: 0.625rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(150%);
  }

  to {
    transform: translateX(0%);
  }
}
