.text {
  max-width: 15.5rem;
  margin: 1rem 0 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;
}

.link {
  height: 3.625rem;
  width: 18.75rem;
  margin: 1rem -1.625rem -1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-ef);
  color: var(--black-1e);
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

.iconBox {
  width: 1.875rem;
  height: 1.875rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black-1e);
  border-radius: 50%;

  svg {
    margin-right: 0.125rem;
  }

  path {
    fill: var(--white);
  }
}
